import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { TABS_DATA } from '../../../features/home/constants';

@Injectable({
	providedIn: 'root'
})
export class MainService {
	menu = TABS_DATA.map((tab, index) => ({
		id: index,
		text: tab.name,
		link: tab.path,
		active: tab.navigates ? 'active' : '',
		tab: '',
		icon: tab.icon
	}));

	constructor() {}
	getMenu() {
		return of(this.menu);
	}
}
