import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-check-email',
	templateUrl: './check-email.component.html',
	styleUrls: ['./check-email.component.scss']
})
export class CheckEmailComponent implements OnInit {
	constructor(private _activeModal: NgbActiveModal) {}

	ngOnInit(): void {}

	closeModal(data?: any) {
		this._activeModal.close(data);
	}
}
