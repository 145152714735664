<footer class="footer">
	<div class="container-footer">
		<div class="row gy-lg-4 gy-md-4 gy-sm-4 gy-xs-3 gy-3">
			<div class="col-lg-12 col-xl-4 col-md-12 col-sm-12 col-xs-12 order-0 content-header-footer">
				<div class="row gy-4">
					<div class="col-xl-12 col-lg-4 col-md-4 col-sm-4 footer__section__logo__header">
						<a [routerLink]="'/'"><img src="./assets/logo_red_gray.svg" alt="NMVIAJES" /></a>
						<span>Copyright 2021 <span class="red">nmviajes</span> All Rights Reserved.</span>
					</div>
					<div class="col-xl-12 col-lg-4 col-md-4 col-sm-4 footer__section__logo__body r_social">
						<section>
							<span class="bold">Razón social:</span>
							<span>PROMOTORA DE TURISMO NUEVO MUNDO S.A.C.</span>
						</section>
						<section>
							<span class="bold r_ruc">RUC:</span>
							<span>20106785288</span>
						</section>
					</div>
					<div class="content-btn-mobile">
						<ng-container *ngTemplateOutlet="buttonStore"></ng-container>
					</div>
					<div class="col-xl-12 col-lg-4 col-md-4 col-sm-4 footer__section__logo__social">
						<span>Síguenos en:</span>
						<div class="footer__section__logo__social__icons">
							<a href="https://www.facebook.com/nmviajes" target="_blank" rel="noopener"
								><img src="./assets/social/facebook.svg" alt="fb"
							/></a>
							<a href="https://www.instagram.com/nmviajes/" target="_blank" rel="noopener"
								><img src="./assets/social/instagram.svg" alt="in"
							/></a>
							<a href="https://www.youtube.com/channel/UCSm8bFv7dgJC8D_WzTqjppA" target="_blank" rel="noopener"
								><img src="./assets/social/youtube.svg" alt="yt"
							/></a>
							<a
								href="https://www.linkedin.com/company/nuevo-mundo-viajes/?originalSubdomain=pe"
								target="_blank"
								rel="noopener"
								><img src="./assets/social/linkendin.svg" alt="ln"
							/></a>
							<a href="https://www.tiktok.com/@nmviajes" target="_blank" rel="noopener"
								><img src="./assets/social/tiktok.svg" alt="tk"
							/></a>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-xl-2 col-md-4 col-sm-4 col-xs-12 order-xl-2 order-lg-3 order-md-3 order-sm-3 order-xs-3">
				<div class="footer__section__links products content-col">
					<div class="footer__section__links__header">
						{{ title1 }}
					</div>
					<div class="footer__section__links__body row-product">
						<ng-container *ngFor="let option of list1; let index">
							<a *ngIf="!option.external && !option.header" (click)="navigate(option)">
								{{ option.text }}
							</a>
							<a *ngIf="option.external && !option.header" [href]="option.link" target="_blank">{{ option.text }}</a>
						</ng-container>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-xl-3 col-md-4 col-sm-4 col-xs-12 order-xl-3 order-lg-2 order-md-2 order-sm-2 order-xs-2">
				<div class="footer__section__links knowus">
					<div class="footer__section__links__header content-col">
						{{ title2 }}
					</div>
					<div class="footer__section__links__body">
						<ng-container *ngFor="let option of list2">
							<a (click)="redirect(option.link)">{{ option.text }}</a>
						</ng-container>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-xl-3 col-md-4 col-sm-4 col-xs-12 order-4 text-center content-col">
				<div class="footer__section__contactus">
					<div class="footer__section__contactus__info">
						<div class="footer__section__contactus__info__header">Contáctanos</div>
						<div class="footer__section__contactus__info__body">
							<div class="footer__section__contactus__info__body__item">
								<img src="./assets/social/location.svg" alt="lo" />
								<span>Av. José Pardo 801, Miraflores - Lima 18, Perú</span>
							</div>
						</div>
						<div class="content-btn-web">
							<ng-container *ngTemplateOutlet="buttonStore"></ng-container>
						</div>
					</div>
					<div class="footer__section__contactus__info">
						<div class="footer__section__contactus__info__header">Habla con nosotros</div>
						<div class="footer__section__contactus__info__body">
							<div class="footer__section__contactus__info__body__item">
								<img src="./assets/social/phone.svg" alt="ph" />
								<span class="bold">Perú <span>01 6109200</span></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>

<ng-template #buttonStore>
	<section>
		<a [href]="'/nuestras-agencias'"
			><app-button [text]="'Tiendas'" [size]="'xs'" [color]="'outline-primary'" [w100]="true"></app-button
		></a>
	</section>
</ng-template>
