import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
	@Input() title1!: string;
	@Input() list1!: any;
	@Input() title2!: string;
	@Input() list2!: any;
	@Input() title3!: string;
	@Input() span!: string;
	@Input() addrees!: string;
	@Input() libro!: string;
	@Input() linkLibro!: string;

	constructor(
		public route: Router,
		@Inject(PLATFORM_ID) private platformId: Object
	) {}

	showMenuProd: Boolean = true;

	showProducto() {
		this.showMenuProd = !this.showMenuProd;
	}

	showMenuConocenos: Boolean = true;

	showConocenos() {
		this.showMenuConocenos = !this.showMenuConocenos;
	}

	redirect(link: string) {
		if (isPlatformBrowser(this.platformId)) {
			if (link.includes('http')) {
				window.open(link, '_blank');
			} else {
				this.route.navigate([link]);
			}
		}
	}

	navigate(option: { link: string; tab: string }) {
		if (isPlatformBrowser(this.platformId)) {
			if (this.route.url.includes(option.link)) {
				window.scroll(0, 0);
				return;
			}
			if (option.tab) {
				this.route.navigate([option.link, option.tab]);
			} else {
				this.route.navigate([option.link]);
			}
		}
	}

	toOficinas() {
		this.route.navigate(['/nuestras-agencias']);
	}
}
