import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { DynamicPackagesService } from '../../../services';
import { IDynamicPackage } from '../../../models';
import { isPlatformBrowser } from '@angular/common';
import { finalize, take } from 'rxjs/operators';
import { ExchangeRate } from '../../../../../api/api-checkout/models/rq-checkout-search';
import { TokenService } from '../../../../../api/api-nmviajes/services/token.service';
import { Subscription } from 'rxjs';
import { ConfigCatService } from '../../../../../shared/services/config-cat.service';

@Component({
	selector: 'app-section-packages',
	templateUrl: './section-packages.component.html',
	styleUrls: [ './section-packages.component.scss' ]
})
export class SectionPackagesComponent implements OnInit, OnDestroy {
	protected _isBrowser = false;
	private _displayEnd = 3;
	private _limit = 6;

	private _exchangeRate: ExchangeRate | null = null;
	private _exchangeSubscription = new Subscription();

	private _flagSubscription = new Subscription();

	isLoading = false;
	placeholderItems = Array(3).fill(0).map((x, i) => i);

	packageList: IDynamicPackage[] = [];
	displayPackageList: IDynamicPackage[] = [];


	constructor(
			@Inject(PLATFORM_ID) private _platformId: Object,
			private _dynamicPackagesService: DynamicPackagesService,
			private _tokenService: TokenService,
			private _configCatService: ConfigCatService
	) {
		if (isPlatformBrowser(this._platformId)) this._isBrowser = true;
	}

	ngOnInit(): void {
		this.getExchangeRate();
		this.listLimitSubscription();
	}

	private listLimitSubscription() {
		this._flagSubscription = this._configCatService.cardListLimitFlag$.subscribe(result => {
			this._limit = result ? 9 : 6;
			this.getDynamicPackages();
		});
	}

	private getExchangeRate() {
		this._exchangeSubscription = this._tokenService.exchangeRate$.subscribe(result => {
			this._exchangeRate = result;
			this.displayPackageList.forEach(item => item.TotalPrice.CurrencyExchange = result);
		});
	}

	private getDynamicPackages() {
		this.isLoading = true;
		this._dynamicPackagesService.getPackages(0, this._limit).pipe(
				take(1),
				finalize(() => {
					if (this._isBrowser) setTimeout(() => this.isLoading = false, 800);
				})
		).subscribe({
			next: (response) => {
				if (response && response.Result) {
					this.packageList = response.Result.package.map(item => this.addExchangeRateToPackage(item));
					this.displayPackageList = [ ...this.packageList ].slice(0, this._displayEnd);
				}
			},
			error: (err) => console.error('Error retrieving packages:', err)
		});
	}

	showMoreLessPackages() {
		if (this._displayEnd < this._limit && this.displayPackageList.length < this.packageList.length) {
			const newDisplayEnd = this._limit;
			this.displayPackageList = this.packageList.map(item => this.addExchangeRateToPackage(item))
					.slice(0, newDisplayEnd);
			this._displayEnd = newDisplayEnd;
			return;
		}
		this._displayEnd = 3;
		this.displayPackageList = this.packageList.map(item => this.addExchangeRateToPackage(item))
				.slice(0, this._displayEnd);
	}

	private addExchangeRateToPackage(item: IDynamicPackage) {
		const result = item;
		result.TotalPrice = {
			...result.TotalPrice,
			CurrencyExchange: this._exchangeRate
		};
		return result;
	}

	ngOnDestroy() {
		this._exchangeSubscription.unsubscribe();
		this._flagSubscription.unsubscribe();
	}
}
