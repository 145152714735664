import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NguCarouselModule } from '@ngu/carousel';
import { BusinessUnitService } from 'src/app/Services/businessUnit/business-unit.service';
import { DestinyService } from 'src/app/Services/destiny/destiny.service';
import { AsidePresenterService } from 'src/app/Services/presenter/aside/aside-presenter.service';
import { ButtonModule } from 'src/app/shared/components/button/button.module';
import { FilterTabsModule } from 'src/app/shared/components/filter-tabs/filter-tabs.module';
import { FiltersafeModule } from 'src/app/shared/components/filtersafe/filtersafe.module';
import { NewCardOfferModule } from 'src/app/shared/components/newcard-offer/newcard.module';
import { CarouselHomeModule } from '../../Component/home-page/carousel-home/carousel-home.module';
import {
	BulletinComponent,
	SearchTabsComponent,
	SectionBlogComponent,
	SectionBoletinComponent,
	SectionPackagesComponent,
	SectionSuperOffersComponent,
	SectionWhyChooseUsComponent,
	VerifiedUserComponent
} from './components';
import { PackageCardComponent } from './components/sections/section-packages/package-card/package-card.component';
import { HomePageComponent, HomeSectionsComponent, HotSaleDetailComponent, HotSalePageComponent } from './pages';
import { HotSaleCardComponent } from './components/hot-sale-card/hot-sale-card.component';
import { SelectModule } from '../../shared/components/select/select.module';
import { LightboxModule } from '../../shared/components/lightbox/lightbox.module';
import { HotSaleContactComponent } from './components/modals/hot-sale-contact/hot-sale-contact.component';
import { InputModule } from '../../shared/components/input/input.module';
import { NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterOutlet } from '@angular/router';
import { CustomButtonModule } from '../../shared/components/custom-button/custom-button.module';
import { CardPlaceholderModule } from '../../shared/components/card-placeholder/card-placeholder.module';

@NgModule({
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
	declarations: [
		HomePageComponent,
		SectionWhyChooseUsComponent,
		SectionBoletinComponent,
		BulletinComponent,
		SectionBlogComponent,
		SectionSuperOffersComponent,
		VerifiedUserComponent,
		SearchTabsComponent,
		SectionPackagesComponent,
		PackageCardComponent,
		HomeSectionsComponent,
		HotSalePageComponent,
		HotSaleCardComponent,
		HotSaleDetailComponent,
		HotSaleContactComponent
	],
	imports: [
		CommonModule,
		RouterOutlet,
		MatSidenavModule,
		FilterTabsModule,
		FiltersafeModule,
		NguCarouselModule,
		ButtonModule,
		ReactiveFormsModule,
		NewCardOfferModule,
		SelectModule,
		LightboxModule,
		InputModule,
		NgbTooltipModule,
		NewCardOfferModule,
		CarouselHomeModule,
		CustomButtonModule,
		CardPlaceholderModule,
		NgbNavModule
	],
	providers: [ DestinyService, AsidePresenterService, BusinessUnitService, FormGroupDirective ]
})
export class HomeModule {
}
