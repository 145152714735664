import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { combineLatest, fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PopupService } from './Services/pop-up/popup.service';
import { environment } from '../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { GoogleSignInUtils } from './shared/utils/google-sign-in-utils';
import { AccountsService } from './Services/accounts.service';
import { NotificationService } from './Services/notification.service';
import { ConfigCatService } from './shared/services/config-cat.service';
import Hotjar from '@hotjar/browser';

declare var google: any;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: [ './app.component.scss' ]
})
export class AppComponent implements OnInit {
	private _isBrowser: Boolean = false;

	title = 'NMViajes';

	constructor(
			@Inject(PLATFORM_ID) private _platformId: Object,
			private _popUpSubject: PopupService,
			private _accountService: AccountsService,
			private _notification: NotificationService,
			private _configCatService: ConfigCatService
	) {
		if (isPlatformBrowser(this._platformId)) this._isBrowser = true;
		this._configCatService.initClient();
	}

	ngOnInit(): void {
		if (this._isBrowser) {
			this.loadHotjar();
			this.initGoogleLogin();
			this.closePopups();
		}
	}

	private initGoogleLogin() {
		google.accounts.id.initialize({
			client_id: environment.googleAccountClientId,
			callback: (response: any) => this.handleGoogleSignIn(response)
		});

		if (!this._accountService.getUserStorage())
			google.accounts.id.prompt();
	}

	private handleGoogleSignIn(response: any) {
		const decoded = GoogleSignInUtils.decodeIdToken(response);
		this.loginWithGoogle(decoded.given_name, decoded.family_name, decoded.email, decoded.sub, decoded.picture);
	}

	private loginWithGoogle(name: string, lastname: string, email: string, sub: string, photo: string) {
		const payload: any = {
			Firstname: name,
			FatherLastname: lastname,
			MotherLastname: '',
			Email: email,
			Password: '',
			IsPerson: true,
			Ruc: '',
			BusinessName: '',
			SocialNetwork: 'G',
			IdSocialNetwork: sub
		};
		this._accountService.saveAccount(payload).subscribe({
			next: (response) => {
				if (response.Result.IsSuccess)
					this._accountService.guardarStorage(response.Result, photo, 'G');
			},
			error: () => this._notification.showNotificacion('Error', 'Error del servidor', 10)
		});
	}

	private loadHotjar() {
		const siteId = 3837481;
		const hotjarVersion = 6;
		Hotjar.init(siteId, hotjarVersion, {
			debug: !environment.production
		});
	}

	private closePopups() {
		if (this._isBrowser) {
			combineLatest([ fromEvent(document, 'click'), this._popUpSubject.state() ]).pipe(
					filter(resp => resp[1].open)
			).subscribe(resp => {
				if (this._isBrowser) {
					const htmlSelected = (resp[0].target as HTMLElement);
					const popUpElement = document.getElementById(resp[1].id);

					if (htmlSelected.contains(popUpElement)) {
						this._popUpSubject.closePopUp('');
					}
				}
			});
		}
	}
}
