import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import * as configcat from 'configcat-js-ssr';

@Injectable({
	providedIn: 'root'
})
export class ConfigCatService {
	private _configCat: configcat.IConfigCatClient;

	private _cardListLimitFlag = new Subject<boolean>();

	get cardListLimitFlag$() {
		return this._cardListLimitFlag.asObservable();
	}

	setCardListLimitFlag(value: boolean) {
		this._cardListLimitFlag.next(value);
	}

	constructor(@Inject(PLATFORM_ID) private _platformId: Object) {
	}

	initClient() {
		const logger = configcat.createConsoleLogger(configcat.LogLevel.Info);
		this._configCat = configcat.getClient(environment.configCatSdkKey, configcat.PollingMode.AutoPoll, {
			logger,
			setupHooks: (hooks) =>
					hooks.on('flagEvaluated', evaluationDetails => {
						const variant = `configcat-${evaluationDetails.key}-${evaluationDetails.value}`;
						if (isPlatformBrowser(this._platformId))
							(window as any).dataLayer.push({
								event: 'abTestVariation',
								variation: variant,
								variation_id: evaluationDetails.variationId
							});
					})
		});
	}

	async getCardListLimitFlag() {
		return await this._configCat.getValueAsync('listadodecardshome', false);
	}
}
