import { IHotSale } from '../models';

export const HOT_SALE_DATA = [
	{
		id: 1,
		title: 'Cyber Days con NM Viajes',
		items: [
			{
				id: 1,
				title: 'Año Nuevo Tarapoto + Equipaje',
				imgUrl: 'https://cdn5.travelconline.com/unsafe/fit-in/500x0/filters:quality(75):format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2FIi1QUqwoUwKfSQOTurY15JQ8.jpg',
				days: 4,
				nights: 3,
				origin: 'Lima (LIM)',
				destination: 'Tarapoto (TPP)',
				tag: '',
				airline: 'SKY',
				price: '578',
				departureDate: '30/12/2024',
				returnDate: '02/01/2025',
				bannerUrl: 'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Fstatic.travelconline.com%2Fimagenes%2FCLKrxtYiV67O-FNXPrBLlCwjpeg.jpeg',
				description: 'Tarapoto, Perú',
				itinerary: [
					{
						day: '30',
						month: 'dic',
						description: '<b>Lima,</b> Perú.<br><b>Transporte:</b> Desde Jorge Chavez Intl (LIM) a Cadete Guillermo Del Castillo Paredes (TPP).'
					},
					{
						day: '30',
						month: 'dic',
						description: '<b>Tarapoto,</b> Perú.<br>' +
								'<b>Traslado:</b> de Cadete Guillermo Del Castillo Paredes (TPP) a Cumbaza Hotel & Convenciones.<br>' +
								'<b>Alojamiento:</b> Cumbaza Hotel & Convenciones.<br>' +
								'<b>3</b> Noches'
					},
					{
						day: '02',
						month: 'ene',
						description: '<b>Traslado:</b> de Cumbaza Hotel & Convenciones a Cadete Guillermo Del Castillo Paredes (TPP).<br>' +
								'<b>Transporte:</b> Desde Cadete Guillermo Del Castillo Paredes a Jorge Chavez Intl.<br>' +
								'<b>Lima,</b> Perú.'
					}
				],
				includes: {
					transport: 2,
					accommodation: 1,
					insurances: 1,
					transfers: 2,
					breakfast: 3,
					baggage: 1
				},
				important: [
					{ description: 'Seguro de Viajes: Nacional - Seguro (Assist Card: Tarjeta de Asistencia ) - Región (País único operador) - Días (4)' },
					{
						description: 'PERU: // El impuesto de servicio lo determinara cada hotel en sus vouchers y en algunos casos se tiene que pagar en destino. // El impuesto ' +
								'del 18% es obligatorio para todos los hoteles y no está incluido en las tarifas, los pasajeros peruanos o con residencia de 90 dias o mas deben ' +
								'pagar el impuesto de acuerdo a la ley.'
					}
				],
				hotelFees: 'Tener en cuenta que en algunos destinos los impuestos hoteleros y locales no están incluidos en la tarifa y se tienen que pagar a la llegada.',
				serviceFees: '',
				maxPassengers: 2,
				dates: [ { value: '30/12/2024', description: 'lunes 30 de diciembre 2024' } ],
				roomType: [ { value: 1, description: 'Standard', price: 578 } ],
				gallery: [
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2F45dWnudj94XS-wNE6TN1Bc4cpjbn.jpg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2Fj9Ua39fOFObW-yszZE8FogQpBIBX.jpg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FpyJjE7Lr2fpI-7nOVGDRlOghUltG.jpg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FRJJ6mM8EqRhu-cI1NAtdzly0UMAF.jpg'
				],
				pdfUrl: 'https://nm-viajes.paquetedinamico.com/brochurePDF?ideaId=11990962&lang=es&currency=USD&agencyId=162512'
			},
			{
				id: 2,
				title: 'Punta Cana - Whala Urban + Equipaje',
				imgUrl: 'https://cdn5.travelconline.com/unsafe/fit-in/500x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2FFNz2Y82WuxkSBnHGTOMDqMKi.jpg',
				days: 5,
				nights: 4,
				origin: 'Lima (LIM)',
				destination: 'Punta Cana Intl (PUJ)',
				tag: '',
				airline: 'LATAM',
				price: '1,830',
				departureDate: '09/09/2024',
				returnDate: '13/09/2024',
				bannerUrl: 'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2Fn8UsK65gWchxLGiEBu-BrzldrpZDOCquCT.png',
				description: 'Punta Cana, República Dominicana',
				itinerary: [
					{
						day: '09',
						month: 'sep',
						description: '<b>Lima,</b> Perú.<br><b>Transporte:</b> Desde Jorge Chavez Intl (LIM)  a Punta Cana Intl (PUJ)'
					},
					{
						day: '09',
						month: 'sep',
						description: '<b>Punta Cana,</b> República Dominicana.<br>' +
								'<b>Traslado:</b> de Punta Cana Intl (PUJ) a wala!urban punta cana<br>' +
								'<b>Alojamiento:</b> wala!urban punta cana<br>' +
								'<b>4</b> Noches.'
					},
					{
						day: '13',
						month: 'sep',
						description: '<b>Traslado:</b> de wala!urban punta cana a Punta Cana Intl (PUJ)<br>' +
								'<b>Transporte:</b> Desde Punta Cana Intl a Jorge Chavez Intl.<br>' +
								'<b>Lima,</b> Perú.'
					}
				],
				includes: {
					transport: 2,
					accommodation: 1,
					transfers: 2,
					breakfast: 4,
					baggage: 1
				},
				important: [
					{
						description: 'PERU: // El impuesto de servicio lo determinara cada hotel en sus vouchers y en algunos casos se tiene que pagar en destino. // El impuesto ' +
								'del 18% es obligatorio para todos los hoteles y no está incluido en las tarifas, los pasajeros peruanos o con residencia de 90 dias o mas deben ' +
								'pagar el impuesto de acuerdo a la ley.'
					}
				],
				hotelFees: 'Tener en cuenta que en algunos destinos los impuestos hoteleros y locales no están incluidos en la tarifa y se tienen que pagar a la llegada.',
				serviceFees: '',
				maxPassengers: 2,
				dates: [ { value: '09/09/2024', description: 'lunes 09 de septiembre 2024' } ],
				roomType: [ { value: 1, description: 'Doble', price: 1830 } ],
				gallery: [
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Fi.travelapi.com%2Flodging%2F22000000%2F21950000%2F21944200%2F21944181%2Fbdfbc34e_z.jpg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Fi.travelapi.com%2Flodging%2F22000000%2F21950000%2F21944200%2F21944181%2F55f3cc48_z.jpg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Fi.travelapi.com%2Flodging%2F22000000%2F21950000%2F21944200%2F21944181%2Fb7a4bf47_z.jpg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Fi.travelapi.com%2Flodging%2F22000000%2F21950000%2F21944200%2F21944181%2F514c1938_z.jpg'
				],
				pdfUrl: 'https://nm-viajes.paquetedinamico.com/brochurePDF?ideaId=11775949&lang=es&currency=USD&agencyId=162512'
			},
			{
				id: 4,
				title: 'Cartagena + Equipaje',
				imgUrl: 'https://cdn5.travelconline.com/unsafe/fit-in/500x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2FRyqjjGCgRInf0pAlMgfNPDGl.jpg',
				days: 5,
				nights: 4,
				origin: 'Lima (LIM)',
				tag: 'Cartagena (CTG)',
				airline: 'LATAM',
				price: '1,310',
				departureDate: '18/09/2024',
				returnDate: '22/09/2024',
				bannerUrl: 'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Fstatic.travelconline.com%2Fimagenes%2FCIqhE6okCcYv-tOuhh07zxaKcPOp.jpeg',
				description: 'Cartagena, Colombia',
				itinerary: [
					{
						day: '18',
						month: 'sep',
						description: '<b>Lima,</b> Perú.<br><b>Transporte:</b> Desde Jorge Chavez Intl (LIM) a Rafael Nunez (CTG)'
					},
					{
						day: '18',
						month: 'sep',
						description: '<b>Cartagena de Indias,</b> Colombia.<br>' +
								'<b>Alojamiento:</b> Hotel Dorado Plaza Centro Histórico.<br>' +
								'<b>4</b> Noches.'
					},
					{
						day: '22',
						month: 'sep',
						description: '<b>Transporte:</b>  Desde Rafael Nunez a Jorge Chavez Intl.<br>' +
								'<b>Lima,</b> Perú.'
					}
				],
				includes: {
					transport: 2,
					accommodation: 1,
					breakfast: 4,
					baggage: 1
				},
				important: [
					{
						description: 'PERU: // El impuesto de servicio lo determinara cada hotel en sus vouchers y en algunos casos se tiene que pagar en destino. // El impuesto ' +
								'del 18% es obligatorio para todos los hoteles y no está incluido en las tarifas, los pasajeros peruanos o con residencia de 90 dias o mas deben ' +
								'pagar el impuesto de acuerdo a la ley.'
					}
				],
				hotelFees: 'Tener en cuenta que en algunos destinos los impuestos hoteleros y locales no están incluidos en la tarifa y se tienen que pagar a la llegada.',
				serviceFees: '',
				maxPassengers: 2,
				dates: [ { value: '18/09/2024', description: 'miércoles 18 de septiembre 2024' } ],
				roomType: [ { value: 1, description: 'Standard', price: 1310 } ],
				gallery: [
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Fi.travelapi.com%2Flodging%2F5000000%2F4690000%2F4683600%2F4683513%2F74e4efb4_z.jpg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Fi.travelapi.com%2Flodging%2F5000000%2F4690000%2F4683600%2F4683513%2Fbb4e0a35_z.jpg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Fi.travelapi.com%2Flodging%2F5000000%2F4690000%2F4683600%2F4683513%2Fw3000h2106x0y4-5e009af7_z.jpg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Fi.travelapi.com%2Flodging%2F5000000%2F4690000%2F4683600%2F4683513%2F757888aa_z.jpg'
				],
				pdfUrl: 'https://nm-viajes.paquetedinamico.com/brochurePDF?ideaId=11955430&lang=es&currency=USD&agencyId=162512'
			},
			{
				id: 5,
				title: 'Año Nuevo Arequipa + Equipaje "30dic al 02ene"',
				imgUrl: 'https://cdn5.travelconline.com/unsafe/fit-in/500x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2F8XFKFf1H04nW0VRxmA8UzAPO.jpg',
				days: 4,
				nights: 3,
				origin: 'Lima (LIM)',
				destination: 'Arequipa (AQP)',
				tag: '',
				airline: 'SKY',
				price: '546',
				departureDate: '30/12/2024',
				returnDate: '02/01/2025',
				bannerUrl: 'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Fstatic.travelconline.com%2Fimagenes%2FA9r0Ddtk8cRA-uS7IIqfBLGjpeg.jpeg',
				description: 'Arequipa, Perú',
				itinerary: [
					{
						day: '30',
						month: 'dic',
						description: '<b>Lima,</b> Perú.<br><b>Transporte:</b> Desde Jorge Chavez Intl (LIM) a Rodriguez Ballon (AQP).'
					},
					{
						day: '30',
						month: 'dic',
						description: '<b>Arequipa,</b> Perú.<br>' +
								'<b>Alojamiento:</b> Casona Plaza Colonial Arequipa.<br>' +
								'<b>3</b> Noches'
					},
					{
						day: '02',
						month: 'ene',
						description: '<b>Transporte:</b> Desde Rodriguez Ballon a Jorge Chavez Intl.<br>' +
								'<b>Lima,</b> Perú.'
					}
				],
				includes: {
					transport: 2,
					accommodation: 1,
					insurances: 1,
					breakfast: 3,
					baggage: 1
				},
				important: [
					{ description: 'Seguro de Viajes: Nacional - Seguro (Assist Card: Tarjeta de Asistencia ) - Región (País único operador) - Días (4)' },
					{
						description: 'PERU: // El impuesto de servicio lo determinara cada hotel en sus vouchers y en algunos casos se tiene que pagar en destino. // El impuesto ' +
								'del 18% es obligatorio para todos los hoteles y no está incluido en las tarifas, los pasajeros peruanos o con residencia de 90 dias o mas deben ' +
								'pagar el impuesto de acuerdo a la ley.'
					}
				],
				hotelFees: 'Tener en cuenta que en algunos destinos los impuestos hoteleros y locales no están incluidos en la tarifa y se tienen que pagar a la llegada.',
				serviceFees: '',
				maxPassengers: 2,
				dates: [ { value: '30/12/2024', description: 'lunes 30 de diciembre 2024' } ],
				roomType: [ { value: 1, description: 'Habitación Estándar', price: 546 } ],
				gallery: [
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FdRrmL7Yiibp8oxeOSm-3D2526hp253D1.jpeg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2Fdo4dKWNEcgrHPEeAW9-3D2526hp253D1.jpeg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FIJgi7Fgj91yfOQ0K1E-3D2526hp253D1.jpeg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FpsnrWCwRIs17gvWHPR-3D2526hp253D1.jpeg'
				],
				pdfUrl: 'https://nm-viajes.paquetedinamico.com/brochurePDF?ideaId=11967868&lang=es&currency=USD&agencyId=162512'
			},
			{
				id: 6,
				title: 'Año Nuevo Arequipa + Equipaje "29dic al 01ene"',
				imgUrl: 'https://cdn5.travelconline.com/unsafe/fit-in/500x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2F8XFKFf1H04nW0VRxmA8UzAPO.jpg',
				days: 4,
				nights: 3,
				origin: 'Lima (LIM)',
				destination: 'Arequipa (AQP)',
				tag: '',
				airline: 'SKY',
				price: '546',
				departureDate: '29/12/2024',
				returnDate: '01/01/2025',
				bannerUrl: 'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Fstatic.travelconline.com%2Fimagenes%2FA9r0Ddtk8cRA-uS7IIqfBLGjpeg.jpeg',
				description: 'Arequipa, Perú',
				itinerary: [
					{
						day: '29',
						month: 'dic',
						description: '<b>Lima,</b> Perú.<br><b>Transporte:</b> Desde Jorge Chavez Intl (LIM) a Rodriguez Ballon (AQP).'
					},
					{
						day: '29',
						month: 'dic',
						description: '<b>Arequipa,</b> Perú.<br>' +
								'<b>Alojamiento:</b> Casona Plaza Colonial Arequipa.<br>' +
								'<b>3</b> Noches'
					},
					{
						day: '01',
						month: 'ene',
						description: '<b>Transporte:</b> Desde Rodriguez Ballon a Jorge Chavez Intl.<br>' +
								'<b>Lima,</b> Perú.'
					}
				],
				includes: {
					transport: 2,
					accommodation: 1,
					insurances: 1,
					breakfast: 3,
					baggage: 1
				},
				important: [
					{ description: 'Seguro de Viajes: Nacional - Seguro (Assist Card: Tarjeta de Asistencia ) - Región (País único operador) - Días (4)' },
					{
						description: 'PERU: // El impuesto de servicio lo determinara cada hotel en sus vouchers y en algunos casos se tiene que pagar en destino. // El impuesto ' +
								'del 18% es obligatorio para todos los hoteles y no está incluido en las tarifas, los pasajeros peruanos o con residencia de 90 dias o mas deben ' +
								'pagar el impuesto de acuerdo a la ley.'
					}
				],
				hotelFees: 'Tener en cuenta que en algunos destinos los impuestos hoteleros y locales no están incluidos en la tarifa y se tienen que pagar a la llegada.',
				serviceFees: '',
				maxPassengers: 2,
				dates: [ { value: '29/12/2024', description: 'domingo 29 de diciembre 2024' } ],
				roomType: [ { value: 1, description: 'Habitación Estándar', price: 546 } ],
				gallery: [
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FdRrmL7Yiibp8oxeOSm-3D2526hp253D1.jpeg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2Fdo4dKWNEcgrHPEeAW9-3D2526hp253D1.jpeg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FIJgi7Fgj91yfOQ0K1E-3D2526hp253D1.jpeg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FpsnrWCwRIs17gvWHPR-3D2526hp253D1.jpeg'
				],
				pdfUrl: 'https://nm-viajes.paquetedinamico.com/brochurePDF?ideaId=11967673&lang=es&currency=USD&agencyId=162512'
			},
			{
				id: 7,
				title: 'Año Nuevo Cajamarca + Equipaje',
				imgUrl: 'https://cdn5.travelconline.com/unsafe/fit-in/500x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fthemes%2FQCCRosKwsw2xdKBlrj-yL8aRyhi6pnxlI7.jpeg',
				days: 4,
				nights: 3,
				origin: 'Lima (LIM)',
				destination: 'Cajamarca (CJA)',
				tag: '',
				airline: 'Star Perú',
				price: '570',
				departureDate: '30/12/2024',
				returnDate: '02/01/2025',
				bannerUrl: 'https://nm-viajes.paquetedinamico.com/javax.faces.resource/images/trip-header/tripheader1.jpg',
				description: 'Cajamarca, Perú',
				itinerary: [
					{
						day: '30',
						month: 'dic',
						description: '<b>Lima,</b> Perú.<br><b>Transporte:</b> Desde Jorge Chavez Intl (LIM) a Mayor General FAP Armando Revoredo Iglesias Airport (CJA).'
					},
					{
						day: '30',
						month: 'dic',
						description: '<b>Cajamarca,</b> Perú.<br>' +
								'<b>Alojamiento:</b> Hotel Guisama.<br>' +
								'<b>3</b> Noches'
					},
					{
						day: '02',
						month: 'ene',
						description: '<b>Transporte:</b> Desde Mayor General FAP Armando Revoredo Iglesias Airport a Jorge Chavez Intl.<br>' +
								'<b>Lima,</b> Perú.'
					}
				],
				includes: {
					transport: 2,
					accommodation: 1,
					insurances: 1,
					breakfast: 3,
					baggage: 1
				},
				important: [
					{ description: 'Seguro de Viajes: Nacional - Seguro (Assist Card: Tarjeta de Asistencia ) - Región (País único operador) - Días (4)' },
					{
						description: 'PERU: // El impuesto de servicio lo determinara cada hotel en sus vouchers y en algunos casos se tiene que pagar en destino. // El impuesto ' +
								'del 18% es obligatorio para todos los hoteles y no está incluido en las tarifas, los pasajeros peruanos o con residencia de 90 dias o mas deben ' +
								'pagar el impuesto de acuerdo a la ley.'
					}
				],
				hotelFees: 'Tener en cuenta que en algunos destinos los impuestos hoteleros y locales no están incluidos en la tarifa y se tienen que pagar a la llegada.',
				serviceFees: '',
				maxPassengers: 2,
				dates: [ { value: '30/12/2024', description: 'lunes 30 de diciembre 2024' } ],
				roomType: [ { value: 1, description: 'Habitación Estándar', price: 570 } ],
				gallery: [
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FGD4LD7xQCmIA6ho03q-d71QLtZzFA5SRsB.jpeg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FFM0M8WDh16Ac5biAcY-fbNqeIj8XAnaKIq.jpeg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FIJgi7Fgj91yfOQ0K1E-3D2526hp253D1.jpeg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FNc6LLHk4r1fsJ6CdFh-YwomrPcfKvQgQd7.jpeg'
				],
				pdfUrl: 'https://nm-viajes.paquetedinamico.com/brochurePDF?ideaId=11965489&lang=es&currency=USD&agencyId=162512'
			},
			{
				id: 8,
				title: 'Año Nuevo Cusco + Equipaje "29dic al 01ene"',
				imgUrl: 'https://cdn5.travelconline.com/unsafe/fit-in/500x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2FRQ5PgRXFf6Fz2nUn1FPqw6BY.jpg',
				days: 4,
				nights: 3,
				origin: 'Lima (LIM)',
				destination: 'Cusco (CUZ)',
				tag: '',
				airline: 'SKY',
				price: '536',
				departureDate: '29/12/2024',
				returnDate: '01/01/2025',
				bannerUrl: 'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Fstatic.travelconline.com%2Fimagenes%2FRE3Kr0YvHV72-O7HCAlQ0w74iOKy.jpeg',
				description: 'Cusco, Perú',
				itinerary: [
					{
						day: '29',
						month: 'dic',
						description: '<b>Lima,</b> Perú.<br><b>Transporte:</b> Desde Jorge Chavez Intl (LIM) a Teniente Alejandro Velasco Astete Intl (CUZ).'
					},
					{
						day: '29',
						month: 'dic',
						description: '<b>Cusco,</b> Perú.<br>' +
								'<b>Alojamiento:</b> Inkarri Regocijo Boutique Hotel.<br>' +
								'<b>3</b> Noches'
					},
					{
						day: '01',
						month: 'ene',
						description: '<b>Transporte:</b> Desde Teniente Alejandro Velasco Astete Intl a Jorge Chavez Intl.<br>' +
								'<b>Lima,</b> Perú.'
					}
				],
				includes: {
					transport: 2,
					accommodation: 1,
					insurances: 1,
					breakfast: 3,
					baggage: 1
				},
				important: [
					{ description: 'Seguro de Viajes: Nacional - Seguro (Assist Card: Tarjeta de Asistencia ) - Región (País único operador) - Días (4)' },
					{
						description: 'PERU: // El impuesto de servicio lo determinara cada hotel en sus vouchers y en algunos casos se tiene que pagar en destino. // El impuesto ' +
								'del 18% es obligatorio para todos los hoteles y no está incluido en las tarifas, los pasajeros peruanos o con residencia de 90 dias o mas deben ' +
								'pagar el impuesto de acuerdo a la ley.'
					}
				],
				hotelFees: 'Tener en cuenta que en algunos destinos los impuestos hoteleros y locales no están incluidos en la tarifa y se tienen que pagar a la llegada.',
				serviceFees: '',
				maxPassengers: 2,
				dates: [ { value: '29/12/2024', description: 'domingo 29 de diciembre 2024' } ],
				roomType: [ { value: 1, description: 'Matrimonial Estándar', price: 536 } ],
				gallery: [
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FhW8fb4hmMlHKCUHGbO-rzD9szxGu84Y8S8.png',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2Fs1U1889XEKMUZMSadr-IRK8k0J6njFqKpf.png',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FXZY2BkqaIEXqD5Lm3L-ZGYRM5O4zqnrb04.png',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FiIUpmDgEC9ZuewJVQj-bZcWDsCdLMg2vIB.png'
				],
				pdfUrl: 'https://nm-viajes.paquetedinamico.com/brochurePDF?ideaId=11994931&lang=es&currency=USD&agencyId=162512'
			},
			{
				id: 9,
				title: 'Año Nuevo Cusco + Equipaje "30dic al 02ene"',
				imgUrl: 'https://cdn5.travelconline.com/unsafe/fit-in/500x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2FRQ5PgRXFf6Fz2nUn1FPqw6BY.jpg',
				days: 4,
				nights: 3,
				origin: 'Lima (LIM)',
				destination: 'Cusco (CUZ)',
				tag: '',
				airline: 'SKY',
				price: '536',
				departureDate: '30/12/2024',
				returnDate: '02/01/2025',
				bannerUrl: 'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Fstatic.travelconline.com%2Fimagenes%2FRE3Kr0YvHV72-O7HCAlQ0w74iOKy.jpeg',
				description: 'Cusco, Perú',
				itinerary: [
					{
						day: '30',
						month: 'dic',
						description: '<b>Lima,</b> Perú.<br><b>Transporte:</b> Desde Jorge Chavez Intl (LIM) a Teniente Alejandro Velasco Astete Intl (CUZ).'
					},
					{
						day: '30',
						month: 'dic',
						description: '<b>Cusco,</b> Perú.<br>' +
								'<b>Alojamiento:</b> Inkarri Regocijo Boutique Hotel.<br>' +
								'<b>3</b> Noches'
					},
					{
						day: '02',
						month: 'ene',
						description: '<b>Transporte:</b> Desde Teniente Alejandro Velasco Astete Intl a Jorge Chavez Intl.<br>' +
								'<b>Lima,</b> Perú.'
					}
				],
				includes: {
					transport: 2,
					accommodation: 1,
					insurances: 1,
					breakfast: 3,
					baggage: 1
				},
				important: [
					{ description: 'Seguro de Viajes: Nacional - Seguro (Assist Card: Tarjeta de Asistencia ) - Región (País único operador) - Días (4)' },
					{
						description: 'PERU: // El impuesto de servicio lo determinara cada hotel en sus vouchers y en algunos casos se tiene que pagar en destino. // El impuesto ' +
								'del 18% es obligatorio para todos los hoteles y no está incluido en las tarifas, los pasajeros peruanos o con residencia de 90 dias o mas deben ' +
								'pagar el impuesto de acuerdo a la ley.'
					}
				],
				hotelFees: 'Tener en cuenta que en algunos destinos los impuestos hoteleros y locales no están incluidos en la tarifa y se tienen que pagar a la llegada.',
				serviceFees: '',
				maxPassengers: 2,
				dates: [ { value: '30/12/2024', description: 'lunes 30 de diciembre 2024' } ],
				roomType: [ { value: 1, description: 'Doble Estándar', price: 536 } ],
				gallery: [
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FhW8fb4hmMlHKCUHGbO-rzD9szxGu84Y8S8.png',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2Fs1U1889XEKMUZMSadr-IRK8k0J6njFqKpf.png',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FXZY2BkqaIEXqD5Lm3L-ZGYRM5O4zqnrb04.png',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FiIUpmDgEC9ZuewJVQj-bZcWDsCdLMg2vIB.png'
				],
				pdfUrl: 'https://nm-viajes.paquetedinamico.com/brochurePDF?ideaId=11995264&lang=es&currency=USD&agencyId=162512'
			},
			{
				id: 10,
				title: 'Año Nuevo Zorritos + Equipaje "29dic al 01ene"',
				imgUrl: 'https://cdn5.travelconline.com/unsafe/fit-in/500x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2FdLVVa84qSGXvHGpOaaaVaV3j.jpg',
				days: 4,
				nights: 3,
				origin: 'Lima (LIM)',
				destination: 'Zorritos (TBP)',
				tag: '',
				airline: 'SKY',
				price: '982',
				departureDate: '29/12/2024',
				returnDate: '01/01/2025',
				bannerUrl: 'https://nm-viajes.paquetedinamico.com/javax.faces.resource/images/trip-header/tripheader1.jpg',
				description: 'Zorritos, Perú',
				itinerary: [
					{
						day: '29',
						month: 'dic',
						description: '<b>Lima,</b> Perú.<br><b>Transporte:</b> Desde Jorge Chavez Intl (LIM) a Pedro Canga (TBP).'
					},
					{
						day: '29',
						month: 'dic',
						description: '<b>Zorritos,</b> Perú.<br>' +
								'<b>Alojamiento:</b> Bamboo Lodge Zorritos.<br>' +
								'<b>3</b> Noches'
					},
					{
						day: '01',
						month: 'ene',
						description: '<b>Transporte:</b> Desde Pedro Canga a Jorge Chavez Intl.<br>' +
								'<b>Lima,</b> Perú.'
					}
				],
				includes: {
					transport: 2,
					accommodation: 1,
					insurances: 1,
					breakfast: 3,
					baggage: 1
				},
				important: [
					{ description: 'Seguro de Viajes: Nacional - Seguro (Assist Card: Tarjeta de Asistencia ) - Región (País único operador) - Días (4)' },
					{
						description: 'PERU: // El impuesto de servicio lo determinara cada hotel en sus vouchers y en algunos casos se tiene que pagar en destino. // El impuesto ' +
								'del 18% es obligatorio para todos los hoteles y no está incluido en las tarifas, los pasajeros peruanos o con residencia de 90 dias o mas deben ' +
								'pagar el impuesto de acuerdo a la ley.'
					}
				],
				hotelFees: 'Tener en cuenta que en algunos destinos los impuestos hoteleros y locales no están incluidos en la tarifa y se tienen que pagar a la llegada.',
				serviceFees: '',
				maxPassengers: 2,
				dates: [ { value: '29/12/2024', description: 'domingo 29 de diciembre 2024' } ],
				roomType: [ { value: 1, description: 'Estándar', price: 982 } ],
				gallery: [
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FcmGwAGXiifnwGoyg7X-lBQZhdLEn9jpeg.jpeg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FDz3Vem0iJFk4Hmgrdx-Pk1iQl9qeGjpeg.jpeg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FkNWxzXJN21XUfyyvDZ-RgpZKGh9c5jpeg.jpeg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2F7ojo5QFNO4IrjHvqQD-eYymOwmkzPjpeg.jpeg'
				],
				pdfUrl: 'https://nm-viajes.paquetedinamico.com/brochurePDF?ideaId=11996428&lang=es&currency=USD&agencyId=162512'
			},
			{
				id: 11,
				title: 'Año Nuevo Zorritos + Equipaje "30dic al 02ene"',
				imgUrl: 'https://cdn5.travelconline.com/unsafe/fit-in/500x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2FdLVVa84qSGXvHGpOaaaVaV3j.jpg',
				days: 4,
				nights: 3,
				origin: 'Lima (LIM)',
				destination: 'Zorritos (TBP)',
				tag: '',
				airline: 'SKY',
				price: '982',
				departureDate: '30/12/2024',
				returnDate: '02/01/2025',
				bannerUrl: 'https://nm-viajes.paquetedinamico.com/javax.faces.resource/images/trip-header/tripheader1.jpg',
				description: 'Zorritos, Perú',
				itinerary: [
					{
						day: '30',
						month: 'dic',
						description: '<b>Lima,</b> Perú.<br><b>Transporte:</b> Desde Jorge Chavez Intl (LIM) a Pedro Canga (TBP).'
					},
					{
						day: '30',
						month: 'dic',
						description: '<b>Zorritos,</b> Perú.<br>' +
								'<b>Alojamiento:</b> Bamboo Lodge Zorritos.<br>' +
								'<b>3</b> Noches'
					},
					{
						day: '02',
						month: 'ene',
						description: '<b>Transporte:</b> Desde Pedro Canga a Jorge Chavez Intl.<br>' +
								'<b>Lima,</b> Perú.'
					}
				],
				includes: {
					transport: 2,
					accommodation: 1,
					insurances: 1,
					breakfast: 3,
					baggage: 1
				},
				important: [
					{ description: 'Seguro de Viajes: Nacional - Seguro (Assist Card: Tarjeta de Asistencia ) - Región (País único operador) - Días (4)' },
					{
						description: 'PERU: // El impuesto de servicio lo determinara cada hotel en sus vouchers y en algunos casos se tiene que pagar en destino. // El impuesto ' +
								'del 18% es obligatorio para todos los hoteles y no está incluido en las tarifas, los pasajeros peruanos o con residencia de 90 dias o mas deben ' +
								'pagar el impuesto de acuerdo a la ley.'
					}
				],
				hotelFees: 'Tener en cuenta que en algunos destinos los impuestos hoteleros y locales no están incluidos en la tarifa y se tienen que pagar a la llegada.',
				serviceFees: '',
				maxPassengers: 2,
				dates: [ { value: '30/12/2024', description: 'lunes 30 de diciembre 2024' } ],
				roomType: [ { value: 1, description: 'Estándar', price: 982 } ],
				gallery: [
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FcmGwAGXiifnwGoyg7X-lBQZhdLEn9jpeg.jpeg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FDz3Vem0iJFk4Hmgrdx-Pk1iQl9qeGjpeg.jpeg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FkNWxzXJN21XUfyyvDZ-RgpZKGh9c5jpeg.jpeg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2F7ojo5QFNO4IrjHvqQD-eYymOwmkzPjpeg.jpeg'
				],
				pdfUrl: 'https://nm-viajes.paquetedinamico.com/brochurePDF?ideaId=11996914&lang=es&currency=USD&agencyId=162512'
			},
			{
				id: 12,
				title: 'Eurissima 2 + Equipaje',
				imgUrl: 'https://cdn5.travelconline.com/unsafe/fit-in/500x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2FYRdAAe3IEtIDo3IXRTZPexHh.jpg',
				days: 17,
				nights: 16,
				origin: 'Lima (LIM)',
				destination: 'París (ORY)',
				tag: '',
				airline: 'AIR EUROPA',
				price: '7,102',
				departureDate: '18/12/2024',
				returnDate: '04/01/2025',
				bannerUrl: 'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Fstatic.travelconline.com%2Fimagenes%2FocFKEUYrz39J-BVrRYQXxkOxJqCj.jpeg',
				description: 'París, Francia. Zurich, Suiza. Milan, Italia. Venecia, Italia. Florencia (Provincia), Italia. Roma, Italia. Costa Azul, Francia. Barcelona, España. Madrid, España.',
				itinerary: [
					{
						day: '18',
						month: 'dic',
						description: '<b>Lima,</b> Perú.<br>Salida en vuelo intercontinental con destino a París.<br>Noche a bordo.'
					},
					{
						day: '19',
						month: 'dic',
						description: '<b>París,</b> Francia.<br>' +
								'Llegada al aeropuerto y traslado al Hotel. Día libre. París, la Ciudad de la Luz.<br>' +
								'Alojamiento.'
					},
					{
						day: '20',
						month: 'dic',
						description: '<b>París,</b> Francia.<br>' +
								'Desayuno buffet. Visita panorámica con guía local de la ciudad de París: la Ópera, el Museo de Orsay, ' +
								'la Plaza de la Concordia, los Campos Elíseos, el Arco del Triunfo, los Inválidos, etc. A continuación, ' +
								'y de forma opcional podremos realizar un espectacular paseo en barco por el río Sena a bordo de los ' +
								'conocidos “Bateaux Parisiens” (Paseo en Bateaux Parisiens incluido en el Europack). A continuación, ' +
								'les recomendamos un almuerzo opcional en un bistró de Montmartre conocido como el Barrio de los ' +
								'Pintores. (Almuerzo incluido en el Europack). Tarde Libre. Esta tarde recomendamos una opcional a la ' +
								'Torre Eiffel. (Subida a la Torre Eiffel 2º piso incluida en el Europack)<br>' +
								'Alojamiento.'
					},
					{
						day: '21',
						month: 'dic',
						description: '<b>París,</b> Francia.<br>' +
								'Desayuno buffet. Día libre para seguir recorriendo esta bella ciudad. Recomendamos por la mañana ' +
								'realizar una excursión opcional a Versalles visitando los Grandes Aposentos Reales, la Galería de los ' +
								'Espejos, y pasear por los jardines de estilo francés. Tarde Libre Por la noche sugerimos acudir ' +
								'opcionalmente a un espectáculo nocturno de Cabaret típico de la noche Parisina.<br>' +
								'Alojamiento.'
					},
					{
						day: '22',
						month: 'dic',
						description: '<b>París / Estrasburgo / Zúrich.</b><br>' +
								'Desayuno buffet. Salida hacia Estrasburgo, sede del Parlamento Europeo, es una impresionante ciudad donde se mezcla la cultura ' +
								'francesa y la cultura alemana. Tiempo libre para callejear por sus típicas calles y contemplar su impresionante catedral. Tiempo ' +
								'libre para almorzar. Salida hacia Zúrich, metrópoli fascinante a orillas del lago con vistas a los Alpes. Llegada y alojamiento en los ' +
								'alrededores.'
					},
					{
						day: '23',
						month: 'dic',
						description: '<b>Zúrich / Lucerna / Milán.</b><br>' +
								'Desayuno buffet. Salida hacia Lucerna, ciudad situada al borde del Lago de los Cuatro Cantones. ' +
								'Tiempo libre para recorrer esta magnífica ciudad y admirar su famoso Puente de la Capilla y la famosa ' +
								'escultura en la pared de roca del León Herido. Recomendamos realizar una excursión opcional al Monte ' +
								'Pilatus, situado en el corazón de los Alpes, subiremos en teleférico a 2.132 mts para admirar un ' +
								'panorama excepcional del lago de los Cuatro Cantones. Tiempo libre para almorzar. Continuaremos ' +
								'recorrido saliendo de Suiza y adentrándonos en Italia, con destino Milán, llegando por la tarde.<br>' +
								'Alojamiento.'
					},
					{
						day: '24',
						month: 'dic',
						description: '<b>Milán / Lago Di Garda / Verona / Venecia.</b><br>' +
								'Desayuno buffet. Hoy nos acercaremos al centro de Milán donde tendremos un breve tiempo libre para ' +
								'acercarnos a ver su fascinante Catedral gótica en la Plaza que lleva su nombre Duomo di Milano, y ' +
								'pasear por la Gallería de Vittorio Emanuele II. Salida de Milán hacia el Lago di Garda, a cuyas ' +
								'orillas encontramos poblaciones tan bellas como Sirmione. Tiempo libre para almorzar. (Almuerzo en un ' +
								'típico restaurante incluido en el Europack). A continuación, seguiremos viaje hacia Verona donde ' +
								'podremos acercarnos a la Arena y rememorar la bella y romántica historia de Romeo y Julieta. ' +
								'Continuación a Venecia (Mestre).<br>' +
								'Llegada y alojamiento.'
					},
					{
						day: '25',
						month: 'dic',
						description: '<b>Venecia / Florencia.</b><br>' +
								'Desayuno buffet. Traslado para tomar el Vaporetto que nos conducirá hasta la Plaza de San Marcos. ' +
								'Visita a pie con guía local por el Puente de los Suspiros, la Basílica de San Marcos, el Palacio Ducal, ' +
								'finalizando en una fábrica de cristal, donde podremos observar el arte del soplado del vidrio. ' +
								'Almuerzo opcional en un restaurante típico en el centro (Almuerzo incluido en el Europack) ' +
								'Tiempo libre en el que le recomendamos opcionalmente dar un paseo en las famosas Góndolas Venecianas. ' +
								'(Paseo en góndola incluido en el Europack).<br>' +
								'Continuación a Florencia, llegada y Alojamiento.'
					},
					{
						day: '26',
						month: 'dic',
						description: '<b>Florencia,</b> Italia.<br>' +
								'Desayuno buffet en el hotel.  Visita panorámica de Florencia, iniciaremos por el mirador de ' +
								'Miguel Ángel, donde disfrutaremos de una espectacular vista del conjunto de la ciudad. ' +
								'Visita a pie con guía local recorriendo los lugares principales: la Piazza del Duomo, ' +
								'donde se encuentra la Catedral de Florencia y la impresionante cúpula de Brunelleschi, ' +
								'el Campanile de Giotto y el Battistero di San Giovanni. Seguiremos hasta el Ponte Vecchio, ' +
								'el lugar con mayor encanto de toda Florencia pasando por la Piazza della Signoria y el Palazzo Vecchio. ' +
								'Tiempo libre para almorzar por su cuenta (Almuerzo incluido en el Europack) Les recomendamos recorrer ' +
								'sus maravillosas calles y visitar opcionalmente alguno de sus grandes museos.<br>' +
								'Alojamiento.'
					},
					{
						day: '27',
						month: 'dic',
						description: '<b>Florencia / Pisa / Siena / Roma.</b><br>' +
								'Desayuno buffet en el hotel. Salida hacia Pisa, para poder conocer la famosa Torre Inclinada situada en ' +
								'la Piazza dei Miracoli junto a la catedral y el Baptisterio. Tiempo libre y salida hacia Siena, ' +
								'la joya de la Toscana, con su Plaza del Campo donde cada año se celebran las tradicionales carreras de ' +
								'caballos durante la Fiesta del Palio. Tiempo libre en Siena antes de continuar nuestra ruta a Roma.<br>' +
								'Alojamiento.'
					},
					{
						day: '28',
						month: 'dic',
						description: '<b>Roma,</b> Italia.<br>' +
								'Desayuno buffet en el hotel. Hoy realizaremos la visita panorámica con guía local de la ciudad donde ' +
								'disfrutaremos de sus calles y avenidas, pasando por los lugares más importantes: Plaza de Venecia, ' +
								'Vía Venetto, los Foros Romanos, El Coliseo (exterior), Arco de Constantino, el Castillo de St. Ángelo, ' +
								'etc. Recomendamos continuar descubriendo los encantos de esta ciudad y opcionalmente la visita de los ' +
								'Museos Vaticanos, donde nos sorprenderá la famosísima Capilla Sixtina y el exterior de la Basílica de ' +
								'San Pedro. (Visita a los Museos Vaticanos incluida en el Europack). Tiempo libre para almorzar. ' +
								'(Almuerzo incluido en el Europack) Tarde libre donde le recomendamos visitar opcionalmente la ' +
								'Roma Barroca incluyendo la Fontana de Trevi.<br>' +
								'Alojamiento.'
					},
					{
						day: '29',
						month: 'dic',
						description: '<b>Roma,</b> Italia.<br>' +
								'Desayuno buffet en el hotel. Día libre para seguir recorriendo esta fascinante ciudad o ' +
								'realizar alguna excursión opcional. Recomendamos visitar opcionalmente Nápoles y Capri. Un viaje para ' +
								'disfrutar del Golfo de Nápoles y una de las islas más bonitas y con más fama. Capri enamora por sus ' +
								'maravillosas bellezas naturales, su historia milenaria, su clima suave y su paisaje luminoso.<br>' +
								'Alojamiento.'
					},
					{
						day: '39',
						month: 'dic',
						description: '<b>Roma / Mónaco / Costa Azul.</b><br>' +
								'Desayuno buffet en el hotel. Salida a primera hora de la mañana hasta llegar al Principado de Mónaco, ' +
								'Ciudad de los Príncipes. Visita a pie de la ciudad, verdadero museo a cielo abierto, incluyendo la ' +
								'Plaza del Casino, única en el mundo. Continuamos hacia la Costa Azul.<br>' +
								'Alojamiento.'
					},
					{
						day: '31',
						month: 'dic',
						description: '<b>Costa Azul / Barcelona.</b><br>' +
								'DDesayuno buffet en el hotel. Salida para recorrer toda la costa francesa bordeando el Golfo de León, ' +
								'atravesar los Pirineos, y llegar a Barcelona, ciudad que tiene una historia fascinante.<br>' +
								'Alojamiento.'
					},
					{
						day: '01',
						month: 'ene',
						description: '<b>Barcelona,</b> España.<br>' +
								'Desayuno buffet en el Hotel. Hoy realizaremos un recorrido panorámico de la ciudad Condal con guía local, ' +
								'pasando por la Sagrada Familia (exterior), la Diagonal, el Paseo de Gracia, Las Ramblas, ' +
								'el Barrio Gótico, la Plaza de España, la Torre Agbar –diseñada por Jean Nouvel- y la montaña de ' +
								'Montjuic desde donde tendremos una impresionante vista panorámica de la ciudad y el puerto. ' +
								'Almuerzo opcional en el Puerto Olímpico (Almuerzo en el Puerto Olímpico incluido en el Europack). ' +
								'Tarde libre para pasear por la ciudad. Recomendamos hacer una visita opcional de la Sagrada Familia o ' +
								'del Camp Nou.<br>' +
								'Alojamiento.'
					},
					{
						day: '02',
						month: 'ene',
						description: '<b>Barcelona / Valencia / Madrid,</b> España.<br>' +
								'Desayuno buffet y salida hacia Valencia una ciudad que cuenta con más de dos mil años de historia y, ' +
								'desde que se fundó en el año 138 a. C., fue hogar de romanos, visigodos y musulmanes. ' +
								'Tiempo libre para visitar su centro histórico, donde destaca la impresionante Catedral, ' +
								'construida sobre un antiguo templo romano que fue posteriormente mezquita, la torre Miguelete, ' +
								'Plaza de la Reina, punto de encuentro de los valencianos, el Palacio de la Seda o el Mercado Central, ' +
								'uno de los más grandes de Europa. En horas de la tarde, continuación a Madrid.<br>' +
								'Alojamiento.'
					},
					{
						day: '03',
						month: 'ene',
						description: '<b>Madrid,</b> España.<br>' +
								'Desayuno buffet. Visita Panorámica de la ciudad con guía local, Puerta de Alcalá, Parque del Retiro, ' +
								'Plaza de Colón, la Plaza de la Cibeles, Gran Vía y Plaza del Callao. Sugerimos visitar opcionalmente el ' +
								'Estadio Santiago Bernabéu (Visita incluida en el Europack). Tarde libre. Opcional a Toledo con almuerzo. ' +
								'(Visita y almuerzo incluidos en el Europack).<br>' +
								'Alojamiento.'
					},
					{
						day: '04',
						month: 'ene',
						description: '<b>Madrid,</b> España.<br>' +
								'Desayuno buffet. Tiempo libre hasta la hora de realizar su traslado al aeropuerto para tomar su vuelo de regreso.'
					}
				],
				includes: {
					transport: 2,
					accommodation: 10,
					insurances: 1,
					breakfast: 16,
					baggage: 1
				},
				important: [
					{
						description: 'Alojamientos previstos: Paris: Campanile Paris Est - Pantin, Lodge In MIS, ibis Paris Porte De Montreuil, Zurich: ' +
								'Mercure Zürich City, Milan: Belstay Milano Assago, Belstay Milano Linate, Venecia: ' +
								'Campanile Venice Mestre, Albatros, Florencia (Provincia): West Florence Hotel, Roma: Hotel The Brand, ' +
								'Papillo Hotels & Resorts Roma, Hotel Pineta Palace, Costa Azul: Hotel Amarante Cannes, Barcelona: ' +
								'Sercotel Porta Barcelona, Hotel Campanile BARCELONA SUD - Cornella, Madrid: Hotel Praga, ' +
								'Hotel Silken Puerta Madrid'
					},
					{ description: 'Seguro de Viajes: Internacional - Seguro (Assist Card: Tarjeta de Asistencia (Cobertura de $80,000)) - Región (Continental) - Días (19)' },
					{
						description: 'PERU: // El impuesto de servicio lo determinara cada hotel en sus vouchers y en algunos casos se tiene que pagar en destino. // El impuesto ' +
								'del 18% es obligatorio para todos los hoteles y no está incluido en las tarifas, los pasajeros peruanos o con residencia de 90 dias o mas deben ' +
								'pagar el impuesto de acuerdo a la ley.'
					}
				],
				hotelFees: 'Tener en cuenta que en algunos destinos los impuestos hoteleros y locales no están incluidos en la tarifa y se tienen que pagar a la llegada.',
				serviceFees: '',
				maxPassengers: 2,
				dates: [ { value: '18/12/2024', description: 'miércoles 18 de diciembre 2024' } ],
				roomType: [ { value: 1, description: 'Varios', price: 7102 } ],
				gallery: [
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2FRlfQT3XNy4wn-RumUXh1EOVjpeg.jpeg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2FLPaoPoNwrkn4-i2EEEao5dpjpeg.jpeg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2FfmNe7JAXWtOp-xXC1KS9YGbjpeg.jpeg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2FN0MfWrOG49pA-PWiSlTl7xlUwdDA.jpeg'
				],
				pdfUrl: 'https://nm-viajes.paquetedinamico.com/brochurePDF?ideaId=9734901&lang=es&currency=USD&agencyId=162512'
			}
		] as IHotSale[]
	}
] as const;